import styled from "styled-components";

const StyleWrapper = styled.div`
  .close {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
  .promo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-top: 90px;
  }
  .header-text {
    font-size: 58px;
  }
  .margin {
    margin-top: 12%;
    margin-bottom: 12%;
  }

  @media (min-width: 440px) and (max-width: 900px) {
    .padding-right {
      padding-right: 25px;
    }
  }
  @media (max-width: 440px) {
    .header-text {
      font-size: 30px;
    }
    .padding-right {
      padding-right: 15px;
    }
    .margin {
      margin-top: 0%;
      margin-bottom: 0%;
    }
  }
  .user-image{
    object-fit: contain;
    width: 40px;
    border-radius: 40px;
  }
  .cart-item-count{
    position: absolute;
    top: 1px;
    right: 9px;
    color: white;
    font-weight: 1000;
    font-family: monospace;
  }
  .flex-item{
    display:flex !important;
  }

  .button-shop{
    height:40px;
  }
 
  @media(min-width:320px) and (max-width:768px){
    .flex-item{
      display:block !important;
    }  
    .button-shop{
      height:30px !important;
    }
  }
 
`;

export default StyleWrapper;
