import styled from "styled-components";

const StyleWrapper = styled.div`
.text-underline {
  text-decoration: underline !important;
}
  .border-black {
    border: 2px solid black;
  }
  .footer-text {
    font-size: 24px;
  }
  .email-text {
    font-size: 30px;
  }
  .input-text {
    font-size: 28px;
  }
  .icon-height {
    height: 40px;
  }

  @media (min-width: 970px) and (max-width: 1900px) {
    .footer-pad {
      padding-top: 45px;
    }
  }
  @media (min-width: 970px) and (max-width: 1235px) {
    .footer-text {
      font-size: 19px;
    }
  }
  @media (max-width: 970px) {
    .padding-bottom {
      padding-bottom: 15px;
    }
    .footer-text {
      font-size: 17px;
    }
    .email-text {
      font-size: 20px;
    }
  }
  @media (max-width: 992px) {
    .footer {
      margin-top: 5%;
    }

    .texts {
      text-align: center !important;
    }
  }
  @media (max-width: 745px) {
    .text-list {
      flex-direction: column;
      text-align: center;
    }
    .remove-padding {
      padding-right: 0px !important;
    }
  }

  @media (max-width: 500px) {
    .input-text {
      font-size: 12px;
    }
    .icon-height {
      height: 30px;
    }
  }

  @media(min-width:320px) and (max-width:375px){
    .privacy-terms {
      font-size:12px;
    }
  }
`;

export default StyleWrapper;
