import styled from "styled-components";

const StyleWrapper = styled.div`
  .close-icon {
    font-size: 50px;
    color: white;
    right: 10px;
    top: 10px;
  }
  .checkout-guest{
    margin: 30px;
    font-size: 40px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
  }
`;

export default StyleWrapper;