import React, { useEffect, useState } from 'react'
import StyledWrapper from './style'
import CheckStatus from '../../assets/images/Group144.png'
import styled from 'styled-components';
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { subscribeActions } from '../../redux/subscribe/actions';
import Header from '../../components/common/Header/Header';
import queryString from 'query-string';
import Api from "../../helper/Api";
import CircularProgress from '@mui/material/CircularProgress';
import { purchaseSuccessEvent } from '../../helper/facebookMetaPixelEvents';

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const { productAdded, subscriptionAdded, cartItems, updateCartItem } = subscribeActions;

const PaymentSuccess = (props) => {

  const dispatch = useDispatch();
  const [customerName, setCustomerName] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    let params = queryString.parse(props.location.search)
    setIsLoader(true)
    Api("POST", `checkout/get-customer-name`, params).then((res) => {
      if (res.data.status) {
        setCustomerName(res.data.data);
        setIsLoader(false)
      }
    });

    purchaseSuccessEvent()

    dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
    dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
    dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
    dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
  }, []);
  return (
    <StyledWrapper>
      <Header />
      {isLoader ? <CircularProgress /> : (
        <>
          <div className='payment-success-message ptp-5'>Payment Successful!</div>
          <div className='payment-success-message payment-message ptp-5'>Thanks for your order, {customerName}!</div>
          <div className='d-flex justify-content'><img width="12%" src={CheckStatus} alt="success"></img></div>
          <div style={{ textAlign: 'center' }} className='ptp-5 pbp-2'>
            <StyledButton
              onClick={() => { props.history.push('/') }}
              className='plrp-5'
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: 50,
                fontSize: 15,
                fontWeight: 700,
              }}>
              Continue Shopping
            </StyledButton>
          </div>
        </>
      )}
    </StyledWrapper>
  )
};

export default withRouter(PaymentSuccess);
