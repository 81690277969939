import styled from 'styled-components';

 const StyledWrapper = styled.div`

 .knife-image{
     height:100%;
     width:100%;
 }
 .email {
    color:white;
    background-color:black;
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
 }

 @media(min-width:320px) and (max-width:500px){
    .email{
      color:white;
      background-color:black;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      margin-top: 3%;
    }
 }
 @media(min-width:500px) and (max-width:710px){
   .email{
     color:white;
     background-color:black;
     font-style: normal;
     font-weight: 700;
     font-size: 20px;
     margin-top: 3%;

   }
}
 .email-address {
    color:white;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size:33px;
 }

 @media(min-width:320px) and (max-width:500px){
   .email-address {
      color:white;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      font-size:15px;
   }
 }
 @media(min-width:500px) and (max-width:710px){
   .email-address {
      color:white;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      font-size:20px;
   }
 }
 .new-email {
   background:black;
   padding-left: 10%;
 }

`;

export default StyledWrapper;