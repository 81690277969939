import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Blokspecs from "../../assets/images/Blokspecs.png";
import CloseIcon from "../../assets/images/Close.svg";

import StyleWrapper from "./style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const TransitionsModal = (props) => {
  return (
    <StyleWrapper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isModalOpen}
        onClose={props.toogle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isModalOpen}>
          <div className="blok-spec" 
          //sx={style}
          >
            <img
              src={Blokspecs}
              alt="specs"
              //height="100%"
              className="position-relative spec-image"
            ></img>

            <img
              src={CloseIcon}
              alt="specs"
              height="25px"
              className="position-absolute top-3 right-4 cursor-pointer"
              onClick={props.toogle}
            ></img>

            {/* <i
              className="fa fa-times position-absolute"
              aria-hidden="true"
              onClick={props.toogle}
              style={{ fontSize: "50px", color: "white" }}
            ></i> */}
          </div>
        </Fade>
      </Modal>
    </StyleWrapper>
  );
};

export default TransitionsModal;
