import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Header from "../../components/common/Header/Header";
import SignUpWrapper from "./style";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import TableImage from "../../assets/images/backImage.png";
import { useFormik } from "formik";
import * as yup from "yup";
import Api from "../../helper/Api";
import { connect, useDispatch } from "react-redux";
import authActions from "../../redux/auth/actions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { signupEvent } from "../../helper/facebookMetaPixelEvents";

const { login, userData } = authActions;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
}));

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("First name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const SignUp = (props) => {
  const classes = useStyles();
  // create state variables for each input
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [state, setState] = useState({
    openError: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openError } = state;

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      };
      setIsLoader(true);
      Api("POST", `auth/sign-up`, params).then((result) => {
        if (result.data.status === 1) {
          dispatch(
            login({
              type: "LOGIN",
              accessToken: result.data.data.access_token,
              userId: result.data.data.id,
            })
          );
          dispatch(
            userData({
              type: "USER_DATA",
              firstName: result.data.data.first_name,
              lastName: result.data.data.last_name,
              Image: result.data.data.profile_photo,
              user_data: result.data.data,
            })
          );
          signupEvent();
          setIsLoader(false);
          setSuccessMessage(result.data.message);
          setState({ ...state, openError: true });
          if (props.location.search === "?isCheckout") {
            var checkoutParams = {
              blok_qty: "",
              is_blok_added: false,
              subscription_id: "",
              subscription_qty: "",
              user_id: result.data.data.id,
            };
            props.cartItems.map((cartItem) => {
              if (cartItem.hasOwnProperty("stripe_price_id")) {
                checkoutParams.subscription_id = cartItem.id;
                checkoutParams.subscription_qty = cartItem.count;
              }
              if (cartItem.hasOwnProperty("isProduct")) {
                checkoutParams.is_blok_added = true;
                checkoutParams.blok_qty = cartItem.count;
              }
            });
            Api("POST", `checkout/create-session`, checkoutParams)
              .then((res) => {
                window.location.href = res.data.data.url;
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            props.history.push("/landing-page");
          }
        } else {
          setIsLoader(false);
          setErrMessage(result.data.message);
          setState({ ...state, openError: true });
        }
      });
    },
  });

  return (
    <SignUpWrapper>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openError}
        autoHideDuration={4000}
        onClose={() => setState({ ...state, openError: false })}
      >
        <Alert
          severity={successMessage !== "" ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {successMessage !== "" ? successMessage : errMessage}
        </Alert>
      </Snackbar>
      <Header></Header>
      <div style={{ position: "relative" }} className="box-back">
        <img
          className="image-table-display"
          src={TableImage}
          alt="table-image"
        ></img>
        <Box className="box-display">
          <div className="d-flex justify-content Gothic-Bold fs-30 mtp-10">
            Sign Up
          </div>
          <div className="d-flex justify-content Gothic-Regular fs-20">
            Enter your details to sign-up
          </div>
          <form onSubmit={formik.handleSubmit} className="form-signup">
            <div className="pbp-5">
              <TextField
                label="First Name"
                id="first_name"
                type="first_name"
                fullWidth
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </div>
            <div className="pbp-5">
              <TextField
                label="Last Name"
                id="last_name"
                type="last_name"
                fullWidth
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </div>
            <div className="pbp-5">
              <TextField
                label="Email"
                id="email"
                type="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="pbp-5">
              <TextField
                label="Password"
                id="password"
                type="password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            <div className="d-flex">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  backgroundColor: "#000000",
                  color: "#FFFFFF",
                  borderRadius: 50,
                  fontSize: 15,
                  fontWeight: 700,
                  padding: "5px  30px",
                }}
                disabled={isLoader ? true : false}
              >
                Sign Up
              </Button>
              {isLoader && <CircularProgress />}
            </div>
          </form>
          <div
            className="text-center pbp-3 cursor-pointer"
            onClick={(e) => {
              props.history.push("/login");
            }}
          >
            Already Registered? Click here to login
          </div>
        </Box>
      </div>
    </SignUpWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionPlans,
    cartItems: state.subscribe.cartItems,
    productAdded: state.subscribe.productAdded,
    userData: state.auth.user_data,
    login: state.auth,
  };
};
export default connect(mapStateToProps)(withRouter(SignUp));
