export const subscribeActions = {
    subscription : (data) => {
        return {
            type: "SUBSCRIBE",
            subscriptionData : data.subscriptionData
        }

    },
    blokprice : (data) => {
        return {
            type: "BLOKPRICE",
            blokPriceData : data.blokPriceData
        }

    },

    cartItems : (data) => {
        return {
            type : "ADD_TO_CART",
            cartItems : data.cartItems
        }
    },

    updateCartItem : (data) => {
        return {
            type : "CART_UPDATED",
            cartItems : data.cartItems
        }
    },

    productAdded : (data) => {
        return {
            type : "PRODUCT_ADDED",
            productAdded: data.productAdded
        }
    },

    subscriptionAdded : (data) => {
        return {
            type : "SUBSCRIPTION_ADDED",
            subscriptionAdded: data.subscriptionAdded
        }
    }
}

//export const subscribeActions;