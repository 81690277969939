import React, { useEffect } from "react";
import StyleWrapper from "./style";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tick from "../../assets/images/Vector1.png";
import ChefHat from "../../assets/images/chef-1.png";
import Fork from "../../assets/images/utensils11.png";
import Phone from "../../assets/images/phone-1.png";
import Button from "@mui/material/Button";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import NewVideo from "../../assets/videos/v3.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
import Slider3 from "../../assets/images/1.png";
import Slider4 from "../../assets/images/3.png";
import Slider1 from "../../assets/images/4.png";
import Slider2 from "../../assets/images/5.png";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import MetaTags from "react-meta-tags";
//import ReactPlayer from 'react-player'

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 2,
};

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#ccc",
    },
  },
  peleton: {
    border: "none",
    fontSize: 60,
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
    background: "#00000",
    fontWeight: 700,
    padding: "25% 15% 5% 5%",
  },
}));

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const featuresArr = [
  {
    id: 1,
    imageUrl: Tick,
    feature: "Digital Display",
  },
  {
    id: 2,
    imageUrl: Tick,
    feature: "Cutting board",
  },
  {
    id: 3,
    imageUrl: Tick,
    feature: "Docking station",
  },
  // {
  //   id : 4,
  //   imageUrl : Tick,
  //   feature : 'In-app grocery delivery'
  // }
];

const LandingPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  return (
    <StyleWrapper {...props}>
      <Helmet>
        <title>
          BLOK | Smart Cutting Board Paired with On-demand Cooking Classes
        </title>
        <meta
          name="title"
          content="BLOK | Smart Cutting Board Paired with On-demand Cooking Classes"
        />
        <meta
          name="description"
          content="With BLOK’s embedded digital display and beautiful wood prep surface, you’ll learn how to cook healthy and delicious meals via on-demand cooking classes led by expert chefs."
        />
      </Helmet>
      {/* <head>
        <meta name="keywords" content="Keywords" />
        <meta property="og:site_name" content="BLOK" />
        <meta property="og:title" content="BLOK | Smart Cutting Board Paired with Live & On-demand Cooking Classes" />
        <meta
          property="og:description"
          content="With BLOK’s embedded digital display and beautiful wood prep surface, you’ll learn how to cook healthy and delicious meals via live and on-demand cooking classes led by expert chefs while interacting with friends and family. And it’s all from the comfort of your own kitchen!"
        />
        <meta property="og:image" content="/favicon-32x32.png" />
      </head> */}
      {/* <MetaTags>
            <title>BLOK26t7</title>
            <meta name="description" content="With BLOK’s embedded digital display and beautiful wood prep surface, you’ll learn how to cook healthy and delicious meals via live and on-demand cooking classes led by expert chefs while interacting with friends and family. And it’s all from the comfort of your own kitchen!" />
            <meta property="og:title" content="BLOK | Smart Cutting Board Paired with Live & On-demand Cooking Classes" />
          </MetaTags> */}
      <Header />
      <Box
        sx={{
          width: "100%",
          backgroundColor: "black",
          paddingTop: 2,
        }}
      >
        {/* <ReactPlayer playing = {true} width = "100%" url = {"v3.mp4"}></ReactPlayer> */}
        <video
          src={NewVideo}
          playsInline
          muted
          loop
          autoPlay
          width="100%"
        ></video>
      </Box>

      <div>
        <Box sx={{ background: "black" }}>
          <Grid
            container
            spacing={2}
            sx={{ paddingTop: 6.5, paddingLeft: 6.5, paddingRight: 6.5 }}
          >
            <Grid item xs={12} md={6} lg={6} sm={12} style={{ zIndex: 1 }}>
              <Paper sx={{ borderRadius: "63px 63px 63px 63px" }}>
                <div className="margin-new">
                  <div className="feature Gothic-Regular Gothic-Bold ">
                    FEATURES:
                  </div>
                  <div className="feature-list">
                    <div className="mtp-3">
                      {featuresArr.map((result) => (
                        <div className="feature-list-item" key={result.id}>
                          <span className="feature-tick">
                            <img
                              className="image-display"
                              src={result.imageUrl}
                            ></img>
                          </span>
                          <div className="feature-name">{result.feature}</div>
                        </div>
                      ))}
                    </div>
                    <div className="button-view">
                      <StyledButton
                        onClick={(e) => {
                          props.history.push("/subscription");
                        }}
                        //variant="outlined"
                        className={`${classes.btn} order-now Gothic Regular `}
                      >
                        Order Now
                      </StyledButton>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={`${classes.peleton} paloton-head`}>
                THE PELOTON OF THE KITCHEN
              </div>
              <div
                className={`${
                  window.innerWidth < 768 && window.innerWidth >= 475
                    ? " pa-0"
                    : window.innerWidth < 475 && window.innerWidth >= 320
                    ? "pa-0"
                    : "pt-26 pb-26 pl-26 pr-26"
                }`}
              >
                <div className="peleton-text Gothic-Regular">
                  With BLOK’s embedded digital display and beautiful wood prep
                  surface, you’ll learn how to cook healthy and delicious meals
                  via on-demand cooking classes led by expert chefs.
                </div>
                <div className="seperate-text pt-20 Gothic-Regular">
                  And it’s all from the comfort of your own kitchen!
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="pbp-3 black-bg row ma-0">
        <div className="col-md-12 col-sm-12 col-lg-12 pbp-3">
          <Slider autoplay={true} {...settings}>
            <img src={Slider1} alt="slider1"></img>
            <img src={Slider2} alt="slider2"></img>
            <img src={Slider3} alt="slider3"></img>
            <img src={Slider4} alt="slider4"></img>
          </Slider>
        </div>
      </div>

      <div className="main-flip row ma-0">
        <div
          className={`flip-card col-md-12 col-sm-12 col-xs-12  ${
            window.innerWidth === 1024
              ? " col-lg-12 mb-30 flex justify-content"
              : "col-lg-4 mb-30"
          }`}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front" style={{ paddingTop: "20%" }}>
              <img className="card-image" src={ChefHat} alt="Avatar"></img>
              <div className="text-title Gothic-Regular">CUTTING</div>
              <div className="text-title Gothic-Regular">BOARD</div>
            </div>
            <div className="flip-card-back d-flex align-center">
              <div className="text-title-new Gothic-Regular">
                Prep your ingredients on the high-quality dark walnut wood
                cutting board while streaming on-demand cooking classes on the
                digital display. When you’re done cooking, simply remove the
                digital display from the cutting board for easy cleaning.
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flip-card col-md-12 col-sm-12 col-xs-12 ${
            window.innerWidth === 1024
              ? " col-lg-12 mb-30 flex justify-content"
              : "col-lg-4 mb-30"
          }`}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front" style={{ paddingTop: "20%" }}>
              <img className="card-image" src={Fork} alt="Avatar"></img>
              <div className="text-title Gothic-Regular">ON-DEMAND</div>
              <div className="text-title Gothic-Regular">COOKING CLASSES</div>
            </div>
            <div className="flip-card-back d-flex align-center">
              <div className="text-title-new Gothic-Regular">
                Choose from a library of fun, high-energy on-demand cooking
                classes led by expert chefs that fit your nutrition and dietary
                needs.
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flip-card col-md-12 col-sm-12 col-xs-12 ${
            window.innerWidth === 1024
              ? " col-lg-12 mb-30 flex justify-content"
              : "col-lg-4 mb-30"
          }`}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img className="card-image" src={Phone} alt="Avatar"></img>
              <div className="text-title Gothic-Regular">SMARTPHONE</div>
              <div className="text-title Gothic-Regular">APP</div>
            </div>
            <div className="flip-card-back d-flex align-center">
              <div className="text-title-new Gothic-Regular">
                All of the digital components in BLOK’s cutting board and
                docking station are controlled by an app that’s compatible with
                both iOS and Android.
                {/* Plus, order all of your ingredients directly through the app!  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box sx={{ background: "black", padding: "5%", width: "100%" }}>
        <div className="d-flex align-center justify-content">
          <StyledButton
            style={{ color: "black", borderRadius: 50, fontWeight: 700 }}
            className={`${
              window.innerWidth >= 320 && window.innerWidth < 475
                ? "fs-20"
                : window.innerWidth >= 475 && window.innerWidth <= 768
                ? "fs-25"
                : "fs-36"
            }  bg-white plp-5 prp-5`}
            onClick={(e) => {
              props.history.push("/subscription");
            }}
          >
            SHOP THE BLOK
          </StyledButton>
        </div>
      </Box>

      <Footer />
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionPlans,
    productAdded: state.subscribe.productAdded,
  };
};

export default connect(mapStateToProps)(LandingPage);
