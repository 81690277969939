import React, { useState } from "react";
import StyleWrapper from "./style";
import InstagramIcon from "../../../assets/images/Instagram.svg";
import FacebookIcon from "../../../assets/images/Facebook.svg";
import LinkedinIcon from "../../../assets/images/Linkedin.svg";
import { withRouter } from "react-router";
import validator from 'validator';
import Api from "../../../helper/Api";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch } from "react-redux";
import authActions from "../../../redux/auth/actions";
import { subscribeActions } from "../../../redux/subscribe/actions";
import { connect } from "react-redux";
import { logoutEvent } from "../../../helper/facebookMetaPixelEvents";

const { updateCartItem, cartItems, productAdded, subscriptionAdded } = subscribeActions;
const { logout, userData } = authActions;

const Footer = (props) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();

  const { vertical, horizontal, openError } = state;
  const handleSubscribe = (newState) => {
    if (validator.isEmail(email)) {
      console.log('correct email');
      Api('post', 'common/subscribe-email', { email: email }).then(res => {
        setEmail('');
        setIsValid(true);
        setAlertMessage(res.data.message);
        setState({ openError: true, ...newState });
      })
        .catch(err => {
          console.log('err', err);
        })
    }
    else {
      setIsValid(false);
      setState({ openError: true, ...newState });
      setAlertMessage('Please enter valid email address !');
    }
  }
  const handleLogout = (newState) => {
    Api('GET', `auth/logout`).then(res => {
      setAlertMessage(res.data.message);
      dispatch(logout({ type: "LOGOUT" }));
      dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
      dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
      dispatch(userData({ type: "USER_DATA", user_data: undefined }))
      dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
      setIsValid(true);
      setState({ openError: true, ...newState });

      logoutEvent()
    })
      .catch(err => {
        setIsValid(false);
        console.log('err', err);
        dispatch(logout({ type: "LOGOUT" }));
        dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
        dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
        dispatch(userData({ type: "USER_DATA", user_data: undefined }))
        dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
        dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
      })
  }

  return (
    <StyleWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={isValid ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <div>
        <div className="row ma-0 ptp-3 plrp-2  ">
          <div className="col-lg-7  col-md-12 col-sm-12 col-xs-12">
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="text"
              className="wp-100 ptbp-1 border-black input-text position-relative pl-3 Gothic-Regular"
              placeholder="EMAIL"
            />
            <button onClick={() => handleSubscribe({ vertical: 'top', horizontal: 'center' })} className="white-text black-bg ptbp-1 border-black letter-spacing-1 input-text position-absolute  right-1 Gothic-Regular">
              SUBSCRIBE
            </button>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center footer">
            <a href="https://www.instagram.com/jointheblok/" target="_blank"><img
              src={InstagramIcon}
              alt="Insta icon"
              className="mr-20 icon-height cursor-pointer "
            ></img></a>
            <a href="https://www.facebook.com/Jointheblok" target="_blank"><img
              src={FacebookIcon}
              alt="FB icon"
              className="mr-20 icon-height cursor-pointer"
            ></img></a>
            <a href="https://www.linkedin.com/company/joinblok/" target="_blank"><img
              src={LinkedinIcon}
              alt="Linkedin icon"
              className="icon-height cursor-pointer"
            ></img></a>
          </div>
        </div>
        <div className="row ma-0 ptp-1 plrp-2">
          <div className=" col-lg-8 footer-pad pbp-2 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-lg-start justify-content-sm-center text-list text-list">
            <div
              className="prp-5 remove-padding footer-text cursor-pointer Gothic-Regular"
              onClick={(e) => {
                props.history.push("/about-us");
              }}
            >
              ABOUT
            </div>
            <div
              className="prp-5 remove-padding footer-text cursor-pointer Gothic-Regular"
              onClick={(e) => {
                props.history.push("/contact-us");
              }}
            >
              CONTACT
            </div>
            {props.userData?.user_data !== undefined ?
              <div
                className="prp-5 remove-padding footer-text cursor-pointer Gothic-Regular"
                onClick={() => handleLogout({ vertical: 'top', horizontal: 'center' })}
              >
                LOGOUT
              </div> :
              <div
                className="prp-5 remove-padding footer-text cursor-pointer Gothic-Regular"
                onClick={(e) => {
                  props.history.push("/login");
                }}
              >
                LOGIN
              </div>
            }

            {/* <div 
            className="prp-5 remove-padding footer-text cursor-pointer Gothic-Regular"
              onClick = {(e)=>{
              props.history.push("/privacy-policy");
              }}
            >
              PRIVACY POLICY
            </div>
            <div className="footer-text cursor-pointer Gothic-Regular"
              onClick = {(e)=>{
              props.history.push("/terms");
              }}
            >
              TERMS & CONDITIONS
            </div> */}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-right texts padding-bottom ">
            <div className=" font-weight-bold Gothic-Regular email-text">
              EMAIL US
            </div>
            <div className="footer-text Gothic-Regular">HELLO@JOINBLOK.COM</div>
            <span
              className="text-underline privacy-terms Gothic-Regular  font-weight-bold letter-spacing-1 black-text cursor-pointer pbp-5"
              onClick={(e) => {
                props.history.push("/privacy-policy");
              }}>
              Privacy Policy
            </span>
            &nbsp; &nbsp;
            <span
              className="text-underline privacy-terms Gothic-Regular  font-weight-bold letter-spacing-1 black-text cursor-pointer"
              onClick={(e) => {
                props.history.push("/terms");
              }}
            >
              Terms & Conditions
            </span>

          </div>
        </div>
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionData,
    cartItems: state.subscribe.cartItems,
    updatedCartItems: state.subscribe.cartItems,
    userData: state.auth.user_data
  }
}

export default connect(mapStateToProps)(withRouter(Footer));
