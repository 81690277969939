import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Box from "@mui/material/Box";
import Header from '../../components/common/Header/Header';
import LoginWrapper from './style';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import TableImage from '../../assets/images/backImage.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Api from "../../helper/Api";
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { subscribeActions } from "../../redux/subscribe/actions";
import { deleteAccountEvent } from '../../helper/facebookMetaPixelEvents';


const { updateCartItem, cartItems, productAdded, subscriptionAdded } = subscribeActions;
const { logout, userData } = authActions;

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   padding: theme.spacing(2),

  //   '& .MuiTextField-root': {
  //     margin: theme.spacing(1),

  //   },
  //   '& .MuiButtonBase-root': {
  //     margin: theme.spacing(2),
  //   },
  // },
}));

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const DeleteAccount = (props) => {
  const classes = useStyles();
  // create state variables for each input
  const dispatch = useDispatch();

  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [state, setState] = useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        email: values.email,
        password: values.password
      }

      Api("POST", `auth/delete-account`, params).then(result => {
        if (result.data.status === 1) {
          setSuccessMessage(result.data.message);
          setState({ ...state, openError: true });
          dispatch(logout({ type: "LOGOUT" }));
          dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
          dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
          dispatch(userData({ type: "USER_DATA", user_data: undefined }))
          dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
          dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
          deleteAccountEvent()
          setTimeout(() => {
            props.history.push("/landing-page");
          }, 2000)
        } else {
          setErrMessage(result.data.message);
          setState({ ...state, openError: true });
        }
      })
    },
  });

  return (
    <LoginWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={successMessage !== '' ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {successMessage !== '' ? successMessage : errMessage}
        </Alert>
      </Snackbar>
      <Header></Header>
      <div style={{ position: 'relative' }} className='box-back'>
        <img className='image-table-display' src={TableImage} alt="table-image"></img>
        <Box className='box-display'>
          <div className='d-flex justify-content Gothic-Bold fs-30 mtp-10'>Delete Your Blok Account</div>
          {/* <div className='d-flex justify-content Gothic-Regular fs-20'>Enter your details to login</div> */}
          <form onSubmit={formik.handleSubmit} className="form-login">
            <div className='pbp-5'>
              <TextField
                label="Email"
                id="email"
                type="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className='pbp-5'>
              <TextField
                label="Password"
                id="password"
                type="password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>

            <div className='d-flex pbp-3'>
              <Button color="primary" variant="contained" fullWidth type="submit" style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: 50,
                fontSize: 15,
                fontWeight: 700,
                padding: "5px  30px"
              }}>
                Delete
              </Button>
            </div>
          </form>
        </Box>
      </div>
    </LoginWrapper>
  );
};

export default withRouter(DeleteAccount);