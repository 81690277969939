import styled from 'styled-components';

const LoginWrapper = styled.div`
.box-display{
    background: white;
    border-radius: 20px;
    position:absolute;
    top:10%;
    left:32%;
    width:36%;
}
.image-table-display{
    width:100%;
}
.box-back{
    position:relative;
    background: black;
}
.forgot-password{
    position:relative;
    bottom:20px;
}
@media(max-width:480px){
    .box-display{
      width:100%;
      left:0%;
      right:0%;
    }
    .box-back{
        padding-top: 70vh;
    }
    .forgot-password{
        bottom:15px;
    }
}
@media(min-width:481px) and (max-width:768px){
    .box-display{
        left:10%;
        width:80%;
    }
    .box-back{
        padding-top: 89%;
    }
}
@media(min-width:768px) and (max-width:1024px){
    .box-display{
        left:25%;
        width:50%;
    }
    .box-back{
        padding-top: 20%;
    }
}
.form-login{
    display: 'flex';
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px
}
`;

export default LoginWrapper;