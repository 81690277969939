import styled from "styled-components";

const StyleWrapper = styled.div`
  .text-underline {
    text-decoration: underline !important;
  }
  .justify-content-evenly {
    justify-content: "space-evenly" !important;
  }
  .black-border {
    border: 2px solid #000000;
  }
  .subscription-text {
    font-size: 90px;
  }
  .header-img {
    width: 100%;
  }
  .desc {
    font-size: 23px;
  }
  .product-title {
    font-size: 36px;
  }
  .detail {
    font-size: 16px;
  }
  .detailed-desc {
    font-size: 21px;
  }
  .title {
    font-size: 50px;
  }
  @media (min-width: 710px) and (max-width: 910px) {
    .subscription-text {
      font-size: 50px;
    }
    .desc {
      font-size: 21px;
      padding-bottom: 10px;
    }
  }
  @media (min-width: 500px) and (max-width: 710px) {
    .subscription-text {
      font-size: 30px;
    }
    .title {
      font-size: 30px;
    }
    .desc {
      font-size: 18px;
      padding-bottom: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 500px) {
    .subscription-text {
      font-size: 25px;
    }
    .title {
      font-size: 25px;
    }
    .desc {
      font-size: 16px;
      padding-bottom: 10px;
    }
    .product-title {
      font-size: 27px;
    }
    .detail {
      font-size: 12px;
    }
    .detailed-desc {
      font-size: 18px;
    }
    .img-height {
      height: 500px;
    }
  }
`;

export default StyleWrapper;
