import "./App.css";
import Routes from "../src/routes/routes";
import "../src/assets/scss/app.scss";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
