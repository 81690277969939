import React, { useState } from "react";
import StyleWrapper from "./style";
import CartIcon from "../../../assets/images/Vector.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon1 from "../../../assets/images/CloseIcons.png";
import TrashIcon from "../../../assets/images/trash.png";
import UserIcon from "../../../assets/images/newImg.jpg";
import Lock from "../../../assets/images/icon.png";
import { makeStyles } from "@mui/styles";
import { withRouter } from "react-router";
import styled from 'styled-components'
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { subscribeActions } from "../../../redux/subscribe/actions";
import CommonModal from "../CommonModal/CommonModal";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Api from "../../../helper/Api";
import authActions from "../../../redux/auth/actions";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { initiateCheckoutEvent } from "../../../helper/facebookMetaPixelEvents";


const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const useStyles = makeStyles((theme) => ({
  drawerWidth: {
    width: "70%",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    paddingRight: 20,
  },
  card: {
    fontSize: 40,
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 700,
  },
  listItem: {
    justifyContent: "flex-start",
    fontWeight: 700,
  },
  input: {
    width: "62%",
    padding: 20,
    border: "1px solid #000000",
  },
  enter: {
    width: "33%",
    padding: 14,
    background: "#000000",
    color: "#ffffff",
    fontSize: 24,
    fontWeight: 700,
  },
  promo: {
    backgroundColor: 'black',
    margin: "5%",
    display: "flex",
    justifyContent: 'center',
    padding: 20,
    cursor: 'pointer'
  },
}));

const { updateCartItem, cartItems, productAdded, subscriptionAdded,blokprice } = subscribeActions;
const { logout, userData } = authActions;
const Header = (props) => {

  const [blokPriceData, setBlokPriceData] = useState([]);


  useEffect(() => {
    Api("GET", `common/blok-price`).then((res) => {
      if (res.data.status) {
        setBlokPriceData(res.data.data);
        console.log(res.data.data);
        dispatch(blokprice({
          type: "BLOKPRICE",
          blokPriceData: res.data.data
        }))
      }
    });
  }, []);


  const [sum, setSum] = useState('');
  const [cartCount, setCartCount] = useState('');
  var total = 0;
  useEffect(() => {
    var totalCartItem = 0;
    props?.updatedCartItems?.map(item => {
      total = total + Number(item.amount * item.count)
      totalCartItem = totalCartItem + Number(item.count)
    })
    setSum(total);
    setCartCount(totalCartItem);
  }, [props.updatedCartItems])

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;


  const openMenu = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (newState) => {
    setAnchorEl(null);
    Api('GET', `auth/logout`).then(res => {
      setSuccessMessage(res.data.message);
      dispatch(logout({ type: "LOGOUT" }));
      dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
      dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
      dispatch(userData({ type: "USER_DATA", user_data: undefined }))
      dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
      setIsValid(true);
      setState({ openError: true, ...newState });

    })
      .catch(err => {
        setIsValid(false);
        setErrMessage(JSON.parse(err.request.response).message);
        setState({ openError: true, ...newState });
        console.log('err', err);
      })
  }
  const toggleDrawer = (openNew) => {
    setOpen(openNew);
  };

  const handleIncrement = (item, val) => {
    let newList = [...props.cartItems];
    let newData = newList.find(x => x.id === Number(item));
    newData.count = newData.count + 1;
    newList.splice(val, 1, newData);
    dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
  }

  const handleDecrement = (item, val) => {
    let newList = [...props.cartItems];
    let newData = newList.find(x => x.id === Number(item));
    if (newData.count > 1) {
      newData.count = newData.count - 1;
      newList.splice(val, 1, newData);
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
    }
    else {
      return;
    }
  }

  const handleDeleteItem = (item, val) => {
    let newList = [...props.cartItems];
    let newData = newList.find(x => x.id === Number(item));
    newList.splice(val, 1);
    if (!newList.length) {
      setSum(0);
      dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
      dispatch(subscriptionAdded({ type: "SUBSCRIPTION_ADDED", subscriptionAdded: false }))
    } else {
      newList.map(filteredItem => {
        if (filteredItem.hasOwnProperty('stripe_price_id')) {
          dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
        } else {
          dispatch(subscriptionAdded({ type: "SUBSCRIPTION_ADDED", subscriptionAdded: false }))
        }
      });
    }
    dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
  }

  const toggle = () => {
    setCheckoutModal(!checkoutModal);
  }
  const handleCheckout = () => {
    if (props.userData?.user_data !== undefined) {
      var checkoutParams = {
        blok_qty: "",
        is_blok_added: false,
        subscription_id: "",
        subscription_qty: "",
        user_id: props.userData.user_data.id
      }

      initiateCheckoutEvent();
      props.cartItems.map(cartItem => {
        if (cartItem.hasOwnProperty('stripe_price_id')) {
          checkoutParams.subscription_id = cartItem.id;
          checkoutParams.subscription_qty = cartItem.count;
        }
        if (cartItem.hasOwnProperty('isProduct')) {
          checkoutParams.is_blok_added = true;
          checkoutParams.blok_qty = cartItem.count
        }

      });
      Api("POST", `checkout/create-session`, checkoutParams).then((res) => {
        window.location.href = res.data.data.url;
      })
        .catch(err => {
          console.log('err', err);
        })
    } else {
      setCheckoutModal(true);
    }
    setOpen(false);
  }
  return (
    <StyleWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={isValid ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {successMessage !== '' ? successMessage : errMessage}
        </Alert>
      </Snackbar>
      <div className="d-flex justify-content-between plrp-2 black-bg white-text">
        <div
          className="header-text Gothic-Regular cursor-pointer"
          onClick={(e) => {
            props.history.push("/");
          }}
        >
          BLOK
        </div>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center ">

            {props.location.pathname === "/landing-page" && (
              <StyledButton style={{ color: 'black', backgroundColor: 'white' }}
                className="cursor-pointer mobile-fonts no-width white-bg black-text mlrp-8 button-shop d-flex justify-content-center align-items-center border-radius-50 font-weight-bold letter-spacing-1 Gothic-Regular"
                onClick={(e) => {
                  props.history.push("/subscription");
                }}
              >
                SHOP THE BLOK
              </StyledButton>
              // {/* </div> */}
            )}
            {props.location.pathname === "/data-deletion-instructions" ? "" :
              <div style={{ position: "relative" }}>
                <img
                  src={CartIcon}
                  onClick={() => toggleDrawer(true)}
                  alt="Cart icon"
                  height="30px"
                  width="30px"
                  className="cursor-pointer"
                />
                <div onClick={() => toggleDrawer(true)} className="cursor-pointer cart-item-count">{cartCount}</div></div>
            }
            {/* {props.userData?.user_data !== undefined ?
              <div>
                <FontAwesomeIcon onClick={() => handleLogout({ vertical: 'top', horizontal: 'center' })} style={{ fontSize: "40px", paddingLeft: "10px", cursor: "pointer" }} icon={faSignOutAlt} />

              </div> : ""} */}
            {/* {props.location.pathname === "/landing-page" && props.userData?.user_data === undefined ?
              <StyledButton style={{ color: 'black', backgroundColor: 'white' }}
                className="cursor-pointer white-bg black-text mlrp-8 wr-10 h-40 d-flex justify-content-center align-items-center border-radius-50 font-weight-bold letter-spacing-1 Gothic-Regular"
                onClick={(e) => {
                  props.history.push("/login");
                }}
              >
                Login
              </StyledButton>
              : ""} */}
          </div>
        </div>
      </div>
      <SwipeableDrawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
        onOpen={() => {
          toggleDrawer(true);
        }}
        anchor="right"
      >
        <div className={classes.close}>
          <img className={`${window.innerWidth < 425 && window.innerWidth >= 320
            ? 'w-20 h-20'
            : 'w-26 h-26'} cursor-pointer`}
            alt="close icon"
            onClick={() => {
              toggleDrawer(false);
            }}
            src={CloseIcon1}
          ></img>
        </div>
        <div className={`${window.innerWidth < 425 && window.innerWidth >= 320
          ? 'fs-18'
          : window.innerWidth >= 425 && window.innerWidth < 768 ? 'fs-20'
            : window.innerWidth >= 768 && window.innerWidth < 1024 ? 'fs-30' : 'fs-40'} ${classes.card} Gothic-Regular mlp-5 mrp-5`}
        >
          YOUR CART
        </div>
        <div className="border-bottom-new"></div>
        {props?.cartItems?.map((result, index) => (
          <div>
            <div key={index} className="grid-view ptp-2 pbp-5 plp-5 prp-5">
              <div className={`${window.innerWidth < 425 && window.innerWidth >= 320
                ? 'fs-15'
                : window.innerWidth >= 425 && window.innerWidth < 768 ? 'fs-20'
                  : window.innerWidth >= 768 && window.innerWidth < 1024 ? 'fs-22' : 'fs-30'} ${classes.listItem} mobile-display`}>
                {/* <div className="mr-2">{result.count + 'x'}</div> */}
                <div style={{ fontFamily: 'system-ui' }}>{result.title !== "BLOK" ? result.title + ' ' + 'Subscription' : result.title}</div>
                <div className="fs-20">{'$' + result.amount}</div>
              </div>
              <div className="d-flex mtp-5">
                <div className="increment-decrement" onClick={() => handleDecrement(result.id, index)}>
                  -
                </div>
                <input style={{ width: 40, height: 35, textAlign: 'center', border: '1px solid', backgroundColor: '#FFFFFF' }} name="quantity" type="text" className="quantity__input" readOnly value={result.count} disabled />
                <div className="increment-decrement" onClick={() => handleIncrement(result.id, index)}>
                  +
                </div>
                <img onClick={() => handleDeleteItem(result.id, index)} className="trash-item  cursor-pointer" src={TrashIcon} alt="trash"></img>
              </div>
            </div>
          </div>
        ))}
        {sum !== 0 ?
          (
            <div>
              <div className="border-bottom-new"></div>
              <div className="blok-total-value fs-30">
                <div className="total-display">Total:</div>
                <div className="value-display">{'$' + sum}</div>
              </div>
              <div className="mtp-2 mlp-5 mrp-5" dangerouslySetInnerHTML={{ __html: blokPriceData.cart_text }}></div>
            </div>
          ) : ""}


        {!props?.cartItems?.length && <div className="empty-cart">Cart is Empty!</div>}
        {props?.cartItems?.length ?
          <div>
            <div onClick={handleCheckout} className={classes.promo}>
              <img src={Lock} className="lock-display mrp-2" alt="lock"></img>
              <button style={{ border: 'none' }} className=" fs-24 f-700 text-white black-bg letter-spacing-1">
                CHECKOUT
              </button>
            </div>
          </div> : ""}
      </SwipeableDrawer>
      {checkoutModal && <CommonModal checkoutModal={checkoutModal} toggle={toggle}></CommonModal>}
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionData,
    cartItems: state.subscribe.cartItems,
    updatedCartItems: state.subscribe.cartItems,
    userData: state.auth.user_data
  }
}

export default connect(mapStateToProps)(withRouter(Header));
