import styled from 'styled-components';
 const StyledWrapper = styled.div`

.payment-fail-message{
    text-align:center;
    font-size:30px;
}
.payment-message{
    color:lightgray;
    font-weight:700;
}
`;

export default StyledWrapper