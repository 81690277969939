import React from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import StyleWrapper from "./style";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon1 from "../../../assets/images/Close.svg";
import Button from "@mui/material/Button";
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Api from "../../../helper/Api";
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import authActions from '../../../redux/auth/actions';
import { withRouter } from 'react-router-dom';

const { login, userData } = authActions;

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;
const CommonModal = (props) => {
  console.log('props', props);
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;

  const handleGuestCheckout = () => {
    var checkoutParams = {
      blok_qty: "",
      is_blok_added: false,
      subscription_id: "",
      subscription_qty: "",
    }
    props.cartItems.map(cartItem => {
      if (cartItem.hasOwnProperty('stripe_price_id')) {
        checkoutParams.subscription_id = cartItem.id;
        checkoutParams.subscription_qty = cartItem.count;
      }
      if (cartItem.hasOwnProperty('isProduct')) {
        checkoutParams.is_blok_added = true;
        checkoutParams.blok_qty = cartItem.count
      }

    });
    Api("POST", `checkout/create-session`, checkoutParams).then((res) => {
      window.location.href = res.data.data.url;
    })
      .catch(err => {
        console.log('err', err);
      })
  }

  const handleLogin = (newState) => {
    const params = {
      email: email,
      password: password
    }
    Api("POST", `auth/login`, params).then(result => {
      if (result.data.status == 1) {
        setSuccessMessage(result.data.message);
        dispatch(login({ type: "LOGIN", accessToken: result.data.data.access_token, userId: result.data.data.id }))
        dispatch(userData({
          type: "USER_DATA",
          firstName: result.data.data.first_name,
          lastName: result.data.data.last_name,
          Image: result.data.data.profile_photo,
          user_data: result.data.data
        }
        ));
        setIsValid(true);
        setState({ openError: true, ...newState });
        var checkoutParams = {
          blok_qty: "",
          is_blok_added: false,
          subscription_id: "",
          subscription_qty: "",
          user_id: result.data.data.id
        }
        props.cartItems.map(cartItem => {
          if (cartItem.hasOwnProperty('stripe_price_id')) {
            checkoutParams.subscription_id = cartItem.id;
            checkoutParams.subscription_qty = cartItem.count;
          }
          if (cartItem.hasOwnProperty('isProduct')) {
            checkoutParams.is_blok_added = true;
            checkoutParams.blok_qty = cartItem.count
          }

        });
        Api("POST", `checkout/create-session`, checkoutParams).then((res) => {
          window.location.href = res.data.data.url;
        })
          .catch(err => {
            console.log('err', err);
          })
      } else {
        setIsValid(false);
        setState({ openError: true, ...newState });
        setErrMessage(result.data.message);
      }

    })
      .catch(err => {
        setIsValid(false);
        setErrMessage(JSON.parse(err.request.response).message);
        setState({ openError: true, ...newState });
      })
  }

  return (
    <StyleWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={isValid ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {successMessage !== '' ? successMessage : errMessage}
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.checkoutModal}
        onClose={props.toogle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.checkoutModal}>
          <Box className='modal-size' >
            <div className='position-relative'>
              <div className='black-bg'><h1 className='text-white pa-10'>BLOK</h1></div>
              <div className='checkout-grid '>
                <div className='checkout-guest'>
                  <div className='fs-25'>Guest Checkout</div>
                  <StyledButton
                    onClick={handleGuestCheckout}
                    className='plrp-10'
                    style={{
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                      borderRadius: 50,
                      fontSize: 15,
                      fontWeight: 700,
                    }}>
                    Check out As Guest
                  </StyledButton>
                </div>
                <div className='checkout-login'>
                  <div className='pbp-2 fs-25'>Login for Checkout</div>
                  <Box className='login-fields'>
                    <form>
                      <div className='pbp-5'>
                        <TextField
                          fullWidth
                          id="outlined-error"
                          label="Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          type="email"
                        />
                      </div>
                      <div className='pbp-4'>
                        <TextField inputProps={{ inputMode: 'password' }}
                          type="password"
                          fullWidth
                          id="outlined-error-helper-text"
                          label="Password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div className='pbp-2'>
                        <StyledButton className='plrp-10'
                          onClick={() => handleLogin({ vertical: 'top', horizontal: 'center' })}
                          style={{
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            borderRadius: 50,
                            fontSize: 15,
                            fontWeight: 700,

                          }}>
                          Login
                        </StyledButton>
                      </div>
                    </form>
                  </Box>
                  <Box>
                    <div className='not-registered'>
                      <span>Not a registered user?</span>
                      <span onClick={() => { props.history.push('/sign-up?isCheckout') }}
                        className=" mlp-1 text-underline-1 Gothic-Regular font-weight-bold black-text cursor-pointer">
                        Create an Account
                      </span>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <img
              src={CloseIcon1}
              alt="specs"
              className="position-absolute top-3 right-2 close-icon cursor-pointer close-image "
              onClick={props.toggle}
            ></img>
          </Box>
        </Fade>
      </Modal>
    </StyleWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionPlans,
    cartItems: state.subscribe.cartItems,
    productAdded: state.subscribe.productAdded,
    userData: state.auth.user_data,
    login: state.auth
  }
}
export default connect(mapStateToProps)((withRouter)(CommonModal));
