import React, { useEffect } from "react";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import StyledWrapper from "./style";
import KnifeBoard from "../../assets/images/boardKnife.png";
import { Helmet } from "react-helmet";
import { contactUsEvent } from "../../helper/facebookMetaPixelEvents";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    contactUsEvent()
  }, []);
  return (
    <StyledWrapper>
        <Helmet>
            <meta name="description" content=" Get your questions answered and the the support you need today." />
        </Helmet>
      <Header />
      <div className="row">
        <div className="col-md-12 col-lg-7 pa-0 ma-0  col-sm-12">
          <img className="knife-image" src={KnifeBoard} alt="knife"></img>
        </div>
        <div className="col-md-12 col-lg-5 col-sm-12 pa-0 ma-0 black-bg d-flex align-center">
          <div className=" new-email Gothic-Regular">
          <div className = "email Gothic-Regular">
              EMAIL US:
          </div>
          <div className = 'email-address Gothic-Regular'>
              HELLO@JOINBLOK.COM
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </StyledWrapper>
  );
};
export default ContactUs;
