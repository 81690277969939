import styled from 'styled-components';
 const StyledWrapper = styled.div`

.payment-success-message{
    text-align:center;
    font-size:30px;
    font-weight:700;
}
.payment-message{
    color:#585858;
    font-weight:500;
}
`;

export default StyledWrapper