const initState = {
   cartItems : [],
   subscriptionPlans: [],
   //productAdded : false
}

export default function rootReducer(state =initState,action){
    switch(action.type){
        case "SUBSCRIBE" : 
        return {
            ...state,
            subscriptionPlans: action.subscriptionData,
        }
        case "ADD_TO_CART" : 
        const updatedState = [...state.cartItems,action.cartItems]
        return {
            ...state,
            cartItems: updatedState,
        }
        case "CART_UPDATED" : 
        return {
            ...state,
            cartItems : action.cartItems
        }
        case "PRODUCT_ADDED" : 
        return {
            ...state,
            productAdded:action.productAdded
        }
        case "SUBSCRIPTION_ADDED" : 
        return {
            ...state,
            subscriptionAdded:action.subscriptionAdded
        }
        default:
            return state;
    }
}