import { Button } from '@mui/material';
import React from 'react'
import { useEffect,useState } from 'react';
import { withRouter } from 'react-router';
import Footer from '../../components/common/Footer/Footer';
import Header from '../../components/common/Header/Header';
import Api from '../../helper/Api';
import { pageViewEvent } from '../../helper/facebookMetaPixelEvents';

const Terms = (props) => {

    const [terms,setTerms] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
        pageViewEvent()
        Api("GET", `common/static-page?type=terms`).then((res) => {
          setTerms(res.data.data)
        });
    
        // eslint-disable-next-line
      }, []);
    return (
        <div>
            <Header/>
            <div className = "d-flex flex-end">
                <Button className = "black-bg" variant = "outlined"
                  sx = {{color:'white',backgroundColor:'black',borderRadius:20,margin:2,cursor:'pointer'}}
                  onClick = {()=>props.history.push('/')}
                >
                  Back
                </Button>
            </div>
           <div className = "d-flex justify-content fs-35 Gothic-Regular pa-10 headline">{terms && terms.title}</div>
           <div className = "pa-20 fs-20 Gothic-Regular" dangerouslySetInnerHTML={{ __html: `${terms && terms.content}` }}></div>
           <Footer/>
        </div>
    )
}

export default withRouter(Terms);
