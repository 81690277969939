import React from "react";
import StyleWrapper from "./style";
import Button from "@mui/material/Button";
import styled from 'styled-components';
import { connect, useDispatch } from "react-redux";
import { subscribeActions } from '../../redux/subscribe/actions';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import { addToCartEvent } from "../../helper/facebookMetaPixelEvents";

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const { cartItems, subscriptionAdded, updateCartItem } = subscribeActions;
const Card = (props) => {
  const dispatch = useDispatch();
  const { subscribeData } = props;
  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [subscriptionAdd, setSubscriptionAdd] = React.useState(false);

  const { vertical, horizontal, openError } = state;

  const handleAddToCart = (id, newState) => {
    props.subscriptionData.map(subscriptionPlan => {
      if (subscriptionPlan.id === Number(id)) {
        let existIndex = props.cartItems.findIndex(item => item.id === Number(id));
        if (existIndex >= 0) {
          let newList = [...props.cartItems];
          let newData = newList.find(x => x.id === Number(id));
          newData.count = newData.count + 1;
          newList.splice(existIndex, 1, newData);
          dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
          setState({ openError: true, ...newState });
          setSubscriptionAdd(true);
        } else {
          if (props.subscriptionAdded === true) {
            setState({ openError: true, ...newState });
            setSubscriptionAdd(false);
          }
          else {
            subscriptionPlan.count = 1;
            dispatch(cartItems({
              type: "ADD_TO_CART",
              cartItems: subscriptionPlan
            }))
            dispatch(subscriptionAdded({
              type: "SUBSCRIPTION_ADDED",
              subscriptionAdded: true
            }))
            setState({ openError: true, ...newState });
            setSubscriptionAdd(true);
          }
        }
      }
    })
    addToCartEvent()
  }
  return (
    <StyleWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={subscriptionAdd ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {subscriptionAdd ? "Subscription added to the cart!" : "You cannot add more than one type of subscription in the cart!"}
        </Alert>
      </Snackbar>
      <div className="border card-width ">
        <div className="white-bg black-text ptbp-1 text-center Gothic-Regular">
          {subscribeData.title}
        </div>
        <div className="black-bg ptbp-6 ">
          <div className="d-flex justify-content-center Gothic-Regular  white-text plrp-5">
            {`＄${subscribeData.display_amount} `}
          </div>
          <div className="d-flex justify-content-center">
            <StyledButton onClick={() => handleAddToCart(subscribeData.id, { vertical: 'top', horizontal: 'center' })}
              //variant="outlined"
              style={{
                backgroundColor: "#FFFFFF",
                color: "#000000",
                borderRadius: 50,
                fontWeight: 700
              }}
              className="btn-add-crt "
            >
              ADD TO CART
            </StyledButton>
          </div>
        </div>
      </div>
    </StyleWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionPlans,
    cartItems: state.subscribe.cartItems,
    productAdded: state.subscribe.productAdded,
    subscriptionAdded: state.subscribe.subscriptionAdded
  }
}

export default connect(mapStateToProps)(Card);
