import styled from "styled-components";

const StyleWrapper = styled.div`
.intro-new{
    font-style: normal;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: left;
    color:#000000;
    padding: 2% 27% 1% 5%;
    font-size:22px;
}
    .intro{
        font-style: normal;
        font-weight: 800;
        letter-spacing: 0em;
        text-align: left;
        color:#000000;
        padding: 2% 27% 1% 5%;
        font-size:33px;
    }
    @media(min-width:320px) and (max-width:500px){
        .intro{
            font-style: normal;
            font-weight: 800;
            letter-spacing: 0em;
            text-align: left;
            color:#000000;
            padding: 2% 27% 1% 5%;
            font-size:25px;
        }
      }
      @media(min-width:500px) and (max-width:710px){
        .intro{
            font-style: normal;
            font-weight: 800;
            letter-spacing: 0em;
            text-align: left;
            color:#000000;
            padding: 2% 27% 1% 5%;
            font-size:30px;
        }
      }
      @media(min-width:1100px) and (max-width:1250px){
        .intro{
            font-style: normal;
            font-weight: 800;
            letter-spacing: 0em;
            text-align: left;
            color:#000000;
            padding: 2% 27% 1% 5%;
            font-size:24px;
        }
      }
    .text-div{
        padding: 0% 11% 3% 5%;
    }
    .image-height {
        height:100vh;
    }
    .text-new {
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.01em;
        text-align: left;
        color:#000000;
        margin-bottom:20px;
        font-size: 12px;
    }
    .text {
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color:#000000;
        margin-bottom:15px;
        font-size: 18px;
    }
    @media(min-width:320px) and (max-width:500px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            margin-bottom:20px;
            font-size: 14px;

        }
      }
      @media(min-width:500px) and (max-width:710px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            margin-bottom:30px;
            font-size:20px;
        }
      }

      @media(min-width:1100px) and (max-width:1150px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 11px;

        }
      }
      @media(min-width:1150px) and (max-width:1210px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 13px;

        }
      }
      @media(min-width:1210px) and (max-width:1270px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 14px;

        }
      }
      @media(min-width:1270px) and (max-width:1330px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 15px;

        }
      }
      @media(min-width:1330px) and (max-width:1390px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 16px;

        }
      }
      @media(min-width:1480px) and (max-width:1700px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size:25px;
        }
      }

      @media(min-width:1700px) and (max-width:1900px){
        .text {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size:27px;
        }
      }
      .text-last-para-new {
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.01em;
        text-align: left;
        color:#000000;
        font-size: 12px;
    }
    .text-last-para {
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color:#000000;
        font-size: 18px;
    }

    @media(min-width:320px) and (max-width:500px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 14px;
        }
      }
      @media(min-width:500px) and (max-width:710px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size:20px;
        }
      }
      @media(min-width:1100px) and (max-width:1150px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 11px;
        }
      }
      @media(min-width:1150px) and (max-width:1210px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 13px;
        }
      }
      @media(min-width:1210px) and (max-width:1270px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 14px;
        }
      }
      @media(min-width:1270px) and (max-width:1330px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 15px;
        }
      }
      @media(min-width:1330px) and (max-width:1390px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size: 16px;
        }
      }
      @media(min-width:1480px) and (max-width:1700px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size:25px;
        }
      }
      @media(min-width:1700px) and (max-width:1900px){
        .text-last-para {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.01em;
            text-align: left;
            color:#000000;
            font-size:27px;
        }
      }
    .shop-btn{
        border:"none" ;
        display:'flex';
        justify-content:'center';
        font-weight:400;
        border:'1px solid black';
        color:'black';
        background-color:'white';
        padding-left: 5%;
        padding-right: 5%;
        font-size: 36px;
    }
`;

export default StyleWrapper;