import styled from 'styled-components';
const StyledWrapper = styled.div`

.main-title {
    font-size: 30px;
    font-weight: 700;
}
.message {
    font-size: 20px;
    padding: 30px 0;
}
li {
    font-size: 18px;
}
`;

export default StyledWrapper