import styled from "styled-components";

const StyleWrapper = styled.div`
  .div-height{
    height: 500px;
  }
  .div-section{
    display: flex;
    padding:30px;
  }
  .title-section{
    display: flex;
    justify-content: center;
  }
  .tick-image{
    object-fit: contain;
    padding-right:20px;
    
  }
  .image-display {
    width:30px;
    margin-right:10px;
  }
  @media(min-width:320px) and (max-width:425px){
    .tick-image{
      object-fit: contain;
      padding-right:20px;
      width:20%
    }
  }
  @media(min-width:425px) and (max-width:500px){
    .tick-image{
      object-fit: contain;
      padding-right:20px;
      width:15%
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .tick-image{
      object-fit: contain;
      padding-right:20px;
      width:15%
    }
  }
  .button-section{
    display: flex;
    justify-content: center;
    margin-top:10px;
  }
  .flip-image {
    background:#FFFFFF;
    object-fit:'contain';
  }
  .flip-image-fork {
    background:#FFFFFF;
    object-fit:'contain';
    width:108px;
    height:108px;
  }
  .flip-image-text {
    background:#FFFFFF;
    object-fit:'contain';
    width:50%;
    height:50%
  }
  .flip-text-card {
    font-weight:400;
    color:#000000;
  }
  .text-title{
    font-weight:400;
    color:#000000;
    font-size:30px;
  } 
  @media(min-width:320px) and (max-width:500px){
    .text-title{
      font-weight:400;
      color:#000000;
      font-size:15px;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .text-title{
      font-weight:400;
      color:#000000;
      font-size:20px;
    }
  }
  .text-title-new{
    font-weight:400;
    color:#000000;
    padding: 30px;
    font-size:22px;
  }
  @media(min-width:320px) and (max-width:500px){
    .text-title-new{
      font-weight:400;
      color:#000000;
      padding: 30px;
      font-size:15px;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .text-title-new{
      font-weight:400;
      color:#000000;
      padding: 30px;
      font-size:20px;
    }
  }
  .feature {
    display: flex;
    justify-content: center;
    padding: 10% 0px 10% 0px;
    border: 7px solid #FFFFFF;
    border-radius: 63px 63px 0px 0px;
    font-size: 75px;
    font-weight: 900;
    letter-spacing: 0.5rem;
    line-height: 92px;
  }
  @media(min-width:320px) and (max-width:500px){
    .feature {
      display: flex;
      justify-content: center;
      border: 7px solid #FFFFFF;
      border-radius: 63px 63px 0px 0px;
      font-size: 30px;
      font-weight: 900;
      padding: 5% 0% 5% 0%;
      letter-spacing:0.2rem;
    }
  }

  @media(min-width:500px) and (max-width:710px){
    .feature {
      display: flex;
      justify-content: center;
      padding: 5% 0% 5% 0%;
      border: 7px solid #FFFFFF;
      border-radius: 63px 63px 0px 0px;
      font-size: 45px;
      font-weight: 900;
      letter-spacing: 0.2rem;
    }
  }
 
  .feature-list {
    background-color:black;
    color:#FFFFFF;
    border: 7px solid #FFFFFF;
    border-radius: 0px 0px 63px 63px;
  }
  .feature-list-item {
    display: flex;
    padding-left:26%;
    padding-right:17%;
    padding-top:5%
    font-weight: 400;
    margin-bottom:3%;
  }
  .feature-name{
    font-size:40px;
  }
  .feature-tick{
    padding-right:4%;
    padding-top:5%;
  }
  @media(min-width:320px) and (max-width:480px){
    .feature-list-item {
      padding-left:0%;
      padding-right:0%;
      padding-top:0%;
      margin-left:20%;
      margin-right:18%;
    }
    .feature-name{
      font-size:20px;
      padding-top:8%;
    }
    .image-display{
      width:20px;
      margin-right:10px;
    }
    .feature-tick{
      width:20px;
      height:20px;
      padding-top:12%;
      padding-right:0%;
      margin-right:4%;
    }
  }
  @media(min-width:481px) and (max-width:767px){
    .feature-list-item {
      padding-left:20%;
      padding-right:20%;
    }
    .feature-name{
      font-size:25px;
      padding-top:8%;
    }
    .image-display{
      width:20px;
    }
    .feature-tick{
      width:30px;
      height:30px;
      padding-top:12%;
      padding-right:0%;
      margin-right:5%;
    }
  }
  @media(min-width:768px) and(max-width:1024px){
    .feature-list-item {
      padding-left:20%;
      padding-right:20%;
    }
    .feature-name{
      font-size:25px;
      padding-top:8%;
    }
    .image-display{
      width:20px;
      height:20px;
    }
    .feature-tick{
      width:30px;
      height:30px;
      padding-top:9%;
      padding-right:0%;
      margin-right:5%;
    }
  }
  @media(min-width:500px) and (max-width:768px){
    .paloton-head {
      padding: 25% 15% 5% 0% !important;
    }
  }
  .feature-list-new {
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size:40px;
  }
  @media(min-width:320px) and (max-width:500px){
    .feature-list-new {
      display: flex;
      justify-content: flex-start;
      font-weight: 400;
      font-size:20px;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .feature-list-new {
      display: flex;
      justify-content: flex-start;
      font-weight: 400;
      font-size:25px;
    }
  }
  .pad{
    padding:15px;
    display:flex;
    justify-content: center;
    font-weight: 400;
  }
  .order-now {
    background: white;
    color: black;
    font-size: 38px;
    font-weight: 700;
    border-radius: 50px;
    padding-right: 10%;
    padding-left: 10%;
    width: 65% !important;
  }
  @media(min-width:320px) and (max-width:500px){
    .order-now {
      background: white;
      color: black;
      font-weight: 700;
      border-radius: 50px;
      padding:2% 5% 2% 5%;
      font-size:16px;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .order-now {
      background: white;
      color: black;
      font-weight: 700;
      border-radius: 50px;
      padding:2% 5% 2% 5%;
      font-size:20px;
    }
  }
  .order-now-mob {
    background: white;
    color: black;
    font-weight: 700;
    border-radius: 50px;
    font-size: 38px;
  }
  .order-now-new {
    background: white;
    color: black;
    font-weight: 700;
    border-radius: 50px;
  }
  .button-view {
    display:flex;
    justify-content:center;
    padding:10% 0% 10% 0%;
  }
  @media(min-width:320px) and (max-width:500px){
    .button-view {
      display:flex;
      justify-content:center;
      padding:5% 0% 10% 0%;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .button-view {
      display:flex;
      justify-content:center;
      padding:5% 0% 15% 0%;
    }
  }
  .paloton-head {
    font-size:60px;
  }
  @media(min-width:320px) and (max-width:500px){
    .paloton-head {
      font-size:30px;
      padding: 25% 15% 5% 0% !important;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .paloton-head {
      font-size:40px;
    }
    .paloton-head {
      font-size:30px;
      padding: 25% 15% 5% 0% !important;
    }
  }
  .peleton-text {
   display:flex;
   justify-content:center;
   color: #FFFFFF; 
   background : #000000;
   font-weight:400;
   font-size:28px;
  }

  @media(min-width:320px) and (max-width:500px){
    .peleton-text {
      display:flex;
      justify-content:center;
      color: #FFFFFF; 
      background : #000000;
      font-weight:400;
      font-size:15px;
     }
  }
  @media(min-width:500px) and (max-width:710px){
    .peleton-text {
      display:flex;
      justify-content:center;
      color: #FFFFFF; 
      background : #000000;
      font-weight:400;
      font-size:21px;
     }
  }

  .seperate-text {
    color: #FFFFFF; 
    background : #000000;
    font-weight:400;
    font-size:28px;
    padding-top:20%;
   }
   @media(min-width:320px) and (max-width:500px){
    .seperate-text {
      color: #FFFFFF; 
      background : #000000;
      font-weight:400;
      font-size:15px;
      padding-bottom:20%;
      padding-top:20%;
     }
     .flip-card{
      height:216px !important;
    }
    .flip-card-front{
      padding-top:7% !important;
    }
  }
  @media(min-width:500px) and (max-width:710px){
    .seperate-text {
      color: #FFFFFF; 
      background : #000000;
      font-weight:400;
      font-size:21px;
      padding-bottom:20%;
      padding-top:20%;
     }
  }

  .main-flip{
    display: flex; 
    background: black;
   }
  .flip-card {
    background-color: transparent;
    perspective: 1000px;
    height:450px;
  }

  .flip-card-inner {
    position: relative;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    height:100%;
    width:100%;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #ffffff;
    color: black;
    padding-top:20%;
  }
  
  .flip-card-back {
    background-color: #ffffff;
    color: black;
    transform: rotateY(180deg);
    font-size:22px;
  }
  .card-image{
    width:108px;
    height:108px;
  }
  .font-size-20 {
    font-size:20px;
  }
  .font-size-25 {
    font-size:25px !important;
  }
  .font-size-30 {
    font-size:30px;
  }
  .font-size-40 {
    font-size:40px;
  }
  .new-card {
    position: absolute;
    left: 20%;
    top: 20%;
  }

`;

export default StyleWrapper;
