import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Box from "@mui/material/Box";
import Header from '../../components/common/Header/Header';
import LoginWrapper from './style';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import TableImage from '../../assets/images/backImage.png';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Api from "../../helper/Api";
import { useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { loginEvent } from '../../helper/facebookMetaPixelEvents';

const { login, userData } = authActions;

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   padding: theme.spacing(2),

  //   '& .MuiTextField-root': {
  //     margin: theme.spacing(1),

  //   },
  //   '& .MuiButtonBase-root': {
  //     margin: theme.spacing(2),
  //   },
  // },
}));

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const Login = (props) => {
  const classes = useStyles();
  // create state variables for each input
  const dispatch = useDispatch();

  const [errMessage, setErrMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [state, setState] = useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        email: values.email,
        password: values.password
      }

      Api("POST", `auth/login`, params).then(result => {
        if (result.data.status === 1) {
          dispatch(login({ type: "LOGIN", accessToken: result.data.data.access_token, userId: result.data.data.id }))
          dispatch(userData({
            type: "USER_DATA",
            firstName: result.data.data.first_name,
            lastName: result.data.data.last_name,
            Image: result.data.data.profile_photo,
            user_data: result.data.data
          }
          ));
          setSuccessMessage(result.data.message);
          setState({ ...state, openError: true });

          loginEvent()
          
          setTimeout(() => {
            props.history.push("/landing-page");
          }, 2000)
        } else {
          setErrMessage(result.data.message);
          setState({ ...state, openError: true });
        }
      })
    },
  });

  return (
    <LoginWrapper>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={successMessage !== '' ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {successMessage !== '' ? successMessage : errMessage}
        </Alert>
      </Snackbar>
      <Header></Header>
      <div style={{ position: 'relative' }} className='box-back'>
        <img className='image-table-display' src={TableImage} alt="table-image"></img>
        <Box className='box-display'>
          <div className='d-flex justify-content Gothic-Bold fs-30 mtp-10'>Login</div>
          <div className='d-flex justify-content Gothic-Regular fs-20'>Enter your details to login</div>
          <form onSubmit={formik.handleSubmit} className="form-login">
            <div className='pbp-5'>
              <TextField
                label="Email"
                id="email"
                type="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className='pbp-5'>
              <TextField
                label="Password"
                id="password"
                type="password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            <div className='wp-100 forgot-password text-right cursor-pointer' onClick={(e) => {
              props.history.push("/forgot-password");
            }}>
              Forgot Password?
            </div>
            <div className='d-flex'>
              <Button color="primary" variant="contained" fullWidth type="submit" style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: 50,
                fontSize: 15,
                fontWeight: 700,
                padding: "5px  30px"
              }}>
                Login
              </Button>
            </div>
          </form>
          <div className='text-center pbp-3 cursor-pointer' onClick={(e) => {
            props.history.push("/sign-up");
          }}>
            New Here? Click here to Register
          </div>
        </Box>
      </div>
    </LoginWrapper>
  );
};

export default withRouter(Login);