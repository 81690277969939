import styled from "styled-components";

const StyleWrapper = styled.div`
  .close-icon {
    font-size: 50px;
    color: white;
    right: 10px;
    top: 10px;
  }
`;

export default StyleWrapper;
