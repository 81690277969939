import React, { useState, useEffect } from "react";
import StyleWrapper from "./style";
import Header from "../../components/common/Header/Header";
import Footer from "../../components/common/Footer/Footer";
import BlokVideo from "../../assets/videos/Blok.mp4";
import Button from "@mui/material/Button";
import Card from "../../components/card/Card";
import Mobile from "../../assets/images/Phone.png";
import Arrow from "../../assets/images/Arrow.svg";
import Modal from "../../components/modal/Modal";
import Api from "../../helper/Api";
import styled from 'styled-components';
import { useDispatch } from "react-redux";
import { subscribeActions } from '../../redux/subscribe/actions';
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import { Helmet } from "react-helmet";
import { addToCartEvent } from "../../helper/facebookMetaPixelEvents";

const { subscription, productAdded, cartItems, updateCartItem, blokprice } = subscribeActions;
const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const Subscription = (props) => {
  const dispatch = useDispatch();


  let textArr = [
    "On-demand cooking classes",
    "Access to our online foodie community",
    "Time spent with high-energy, professional chefs",
    "Healthy & delicious recipes",
    // "In-app grocery delivery",
    "...& more!",
  ];
  const planArray = [
    {
      type: "MONTHLY",
      price: "39.99",
      benifite: [
        "ON-DEMAND COOKING CLASSES",
        "SLEEK DIGITAL DISPLAY",
        "CUTTING BOARD",
        "DOCKING STATION",
        "SMARTPHONE APP",
        "RELATABLE, FUN CHEFS",
      ],
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productAdd, setProductAdd] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [blokPriceData, setBlokPriceData] = useState([]);

  const [state, setState] = React.useState({
    openError: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, openError } = state;

  useEffect(() => {
    setProductAdd(false);
    window.scrollTo(0, 0);
    Api("GET", `common/subscription-list`).then((res) => {
      if (res.data.status) {
        setSubscriptionData(res.data.data);
        dispatch(subscription({
          type: "SUBSCRIBE",
          subscriptionData: res.data.data
        }))
      }
    });
    Api("GET", `common/blok-price`).then((res) => {
      if (res.data.status) {
        setBlokPriceData(res.data.data);
        console.log(res.data.data);
        dispatch(blokprice({
          type: "BLOKPRICE",
          blokPriceData: res.data.data
        }))
      }
    });
  }, []);

  const handleAddToCart = (newState) => {
    let existIndex = props.cartItems.findIndex(item => item.id === 0);
    if (existIndex >= 0) {
      let newList = [...props.cartItems];
      let newData = newList.find(x => x.id === 0);
      newData.count = newData.count + 1;
      newList.splice(existIndex, 1, newData);
      dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: newList }))
      setState({ openError: true, ...newState });
    } else {
      setProductAdd(true);
      const blokProduct = {
        id: 0,
        title: "BLOK",
        amount: blokPriceData.price,
        isProduct: true,
        count: 1
      }
      dispatch(cartItems({ type: "ADD_TO_CART", cartItems: blokProduct }))
      dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: true }))
      setState({ openError: true, ...newState });
 
    }
    addToCartEvent();
  }

  const toogle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubscribeScroll = () => {
    const eleId = document.getElementById('subscribe');
    eleId.scrollIntoView(0, 0);
  }
  return (
    <StyleWrapper>
       <Helmet>
            <meta name="description" content="Shop our smart cutting board and subscription options to our mobile application." />
        </Helmet>
      <Header />
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openError} autoHideDuration={4000} onClose={() => setState({ ...state, openError: false })}>
        <Alert severity={productAdd ? "success" : "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState({ ...state, openError: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {"Product added to the cart"}
        </Alert>
      </Snackbar>
      <div className="row mt-1 ma-0">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center black-bg ma-0 pa-0 ">
          <video src={BlokVideo} playsInline autoPlay loop muted width="100%"></video>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ptbp-5 plrp-5">
          <div className="product-title Gothic-Regular font-weight-bold letter-spacing-5 black-text pl-3">
            BLOK CUTTING BOARD
          </div>

          <div className="fs-60 Gothic-Regular font-weight-bold letter-spacing-5 black-text mtp-1 pr-1">
            ＄{blokPriceData.price}
          </div>
          <div className="detail d-flex  mtbp-2 pl-3">
            <span onClick={handleSubscribeScroll} className="text-underline Gothic-Regular font-weight-bold letter-spacing-1 black-text cursor-pointer">
              Add a subscription
            </span>
            &nbsp; &nbsp;
            <span
              className="text-underline Gothic-Regular font-weight-bold letter-spacing-1 black-text cursor-pointer"
              onClick={toogle}
            >
              View specs
            </span>
          </div>
          {planArray[0].benifite.map((result) => {
            return (
              <div className="detailed-desc Gothic-Regular letter-spacing-1 mtbp-1 pl-3 prp-3 d-flex ">
                <div className="prp-3">-</div> {`${result}`}
              </div>
            );
          })}
          <div className="d-flex justify-content-center mt-5">
            <StyledButton onClick={() => handleAddToCart({ vertical: 'top', horizontal: 'center' })}
              //variant="outlined"
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: 50,
                fontSize: 20,
                fontWeight: 700,
                width: 250
              }}
            >
              ADD TO CART
            </StyledButton>
          </div>
        </div>
      </div>

      <div className="subscription-text Gothic-Regular white-text black-bg text-center ptbp-2">
        SHOP SUBSCRIPTIONS
      </div>
      <div id="subscribe"
        className="d-flex black-bg ptbp-2 "
        style={{ justifyContent: "space-evenly", flexWrap: "wrap" }}
      >
        {subscriptionData.length &&
          subscriptionData.map((result) => {
            return (
              <div className="pt-20">
                <Card subscribeData={result} />
              </div>
            );
          })}
      </div>
      <div className="row ptbp-5 plrp-5 ma-0 pa-0 black-bg">
        <div className="col-lg-7 border white-bg ma-0 pa-0">
          <div className=" text-center ptbp-3 letter-spacing-1 font-weight-bold Gothic-Regular title">
            WHAT’S INCLUDED
          </div>
          <hr className="ma-0 pa-0  black-border"></hr>
          <div className="ptbp-3 plrp-2 text-center desc Gothic-Regular letter-spacing-3 font-weight-bold">
            With a BLOK subscription, you’ll have access to:
          </div>
          <div className=" font-weight-bold desc Gothic-Regular letter-spacing-3 font-weight-bold">
            {textArr.map((result) => {
              return (
                <div className="ptbp-1 plp-25 prp-16 d-flex">
                  <span className="prp-3">
                    <img src={Arrow} alt="arrow"></img>
                  </span>
                  {`${result}`}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-lg-5 col-xs-12 border black-bg d-flex justify-content-center ma-0 ptbp-2">
          <img src={Mobile} alt="mobile" className="img-height"></img>
        </div>
      </div>
      <Footer />
      {isModalOpen && <Modal isModalOpen={isModalOpen} toogle={toogle} />}
    </StyleWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    subscriptionData: state.subscribe.subscriptionPlans,
    cartItems: state.subscribe.cartItems,
    productAdded: state.subscribe.productAdded
  }
}
export default connect(mapStateToProps)(Subscription);
