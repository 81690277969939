import styled from "styled-components";

const StyleWrapper = styled.div`
  .card-width {
    width: 650px;
    font-size: 50px;
  }
  .btn-add-crt {
    width: 250px;
    font-size: 20px;
  }
  @media (min-width: 1230px) and (max-width: 1400px) {
    .card-width {
      width: 600px !important;
    }
  }
  @media (min-width: 910px) and (max-width: 1230px) {
    .card-width {
      width: 450px !important;
    }
  }
  @media (min-width: 710px) and (max-width: 910px) {
    .card-width {
      width: 350px !important;
      font-size: 30px !important;
    }
    .btn-add-crt {
      width: 200px !important;
      font-size: 18px !important;
    }
  }
  @media (min-width: 500px) and (max-width: 710px) {
    .card-width {
      width: 250px !important;
      font-size: 20px !important;
    }
    .btn-add-crt {
      width: 150px !important;
      font-size: 16px !important;
    }
  }
  @media (min-width: 420px) and (max-width: 500px) {
    .card-width {
      width: 200px !important;
      font-size: 20px !important;
    }
    .btn-add-crt {
      width: 130px !important;
      font-size: 13px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 420px) {
    .card-width {
      width: 150px !important;
      font-size: 16px !important;
    }
    .btn-add-crt {
      width: 110px !important;
      font-size: 11px !important;
    }
  }
`;

export default StyleWrapper;
