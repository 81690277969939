import React, { useEffect } from 'react'
import StyledWrapper from './style'
import errorStatus from '../../assets/images/Group154.png'
import styled from 'styled-components';
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';
import Header from '../../components/common/Header/Header';
import { useDispatch } from 'react-redux';
import {subscribeActions} from '../../redux/subscribe/actions';
import { paymentFailedEvent } from '../../helper/facebookMetaPixelEvents';

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const {productAdded,subscriptionAdded,cartItems,updateCartItem} = subscribeActions;

const PaymentFailed = (props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(productAdded({type:"PRODUCT_ADDED",productAdded:false}))
    dispatch(subscriptionAdded({type:"PRODUCT_ADDED",subscriptionAdded:false}))
    dispatch(cartItems({type:"ADD_TO_CART",cartItems:[]}))
    dispatch(updateCartItem({type:"CART_UPDATED",cartItems:[]}))
    paymentFailedEvent()
  },[]);
    return (
        <StyledWrapper>
          <Header/>
           <div className='payment-fail-message ptp-5'>Payment Failed!</div> 
           <div className='payment-fail-message payment-message ptp-5'>Sorry! Your payment has failed</div>
           <div className='d-flex justify-content'><img width="12%" src = {errorStatus} alt ="success"></img></div>
           <div style={{textAlign:'center'}} className='ptp-5 pbp-2'>
                    <StyledButton 
                            onClick={()=>{props.history.push('/')}}
                            className='plrp-5'
                            style={{
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            borderRadius: 50,
                            fontSize: 15,
                            fontWeight: 700,
                            }}> 
                            Ok  
                    </StyledButton>
           </div>  
        </StyledWrapper>
    )
}

export default withRouter(PaymentFailed)
