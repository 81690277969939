import React, { useEffect } from 'react'
import StyledWrapper from './style'
import styled from 'styled-components';
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';
import Header from '../../components/common/Header/Header';
import { useDispatch } from 'react-redux';
import { subscribeActions } from '../../redux/subscribe/actions';

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;

const { productAdded, subscriptionAdded, cartItems, updateCartItem } = subscribeActions;

const PaymentFailed = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productAdded({ type: "PRODUCT_ADDED", productAdded: false }))
    dispatch(subscriptionAdded({ type: "PRODUCT_ADDED", subscriptionAdded: false }))
    dispatch(cartItems({ type: "ADD_TO_CART", cartItems: [] }))
    dispatch(updateCartItem({ type: "CART_UPDATED", cartItems: [] }))
  }, []);
  return (
    <StyledWrapper>
      <Header />
      <div className='container'>
        <div className='row'>
          <div className='main-title ptp-5'>DATA DELETION INSTRUCTIONS</div>
          <div className='message col-md-8'>Blok does not store your personal data; it is used only for login. According to the Facebook Platform rules, we have to provide <b>User Data Deletion Callback URL</b> or <b>Data Deletion Instructions URL</b>. If you want to delete your activities for the Blok, following these instructions:</div>
          <ol>
            <li>
              Go to Your Facebook Account’s Setting & Privacy. Click ” Setting “.
            </li>
            <li>
              Then, go to ” Apps and Websites” and you will see all of your Apps activities.
            </li>
            <li>
              Select the option box for Blok
            </li>
            <li>
              Click ” Remove” button.
            </li>
          </ol>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default withRouter(PaymentFailed)
