import React, { useEffect } from "react";
import Header from "../../components/common/Header/Header";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import StyleWrapper from "./style";
import CoupleIcon from "../../assets/images/studio7.png";
import Footer from "../../components/common/Footer/Footer";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
  &:hover {
    background-color: grey;
  }
`;


const AboutUs = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StyleWrapper>
        <Helmet>
            <meta name="description" content=" Learn more about how this smart technology and community of foodies was born." />
        </Helmet>
      <Header />
      <div className="row ma-0">
        <div className="col-md-12 col-lg-5 pa-0 ma-0  col-sm-12">
          <img
            className={`${
              window.innerWidth >= 1024 && window.innerWidth <= 1400
                ? ""
                : "hp-100"
            } wp-100`}
            src={CoupleIcon}
            alt="couple"
          ></img>
        </div>
        <div className="col-md-12 col-lg-7 pa-0 ma-0  col-sm-12">
          <div
          className = {`${window.innerWidth >= 1024 && window.innerWidth <=1100 ? 'intro-new' : 'intro'} Gothic-Regular Gothic-Bold`}
          >
            Hi, we’re Tony and Alissa, creators of BLOK!
          </div>
          <div className="text-div">
            <div
              className = {`${window.innerWidth >= 1024 && window.innerWidth <1100 ? 'text-new' : 'text'} Gothic-Regular`}
            >
              As husband and wife co-founders, it goes without saying, BLOK is a true partnership. Tony describes himself as a “reformed” lawyer who has spent the last decade of his professional life leading healthcare, wellness and technology companies. Alissa has spent her entire career in digital media and television production. She also has two Emmys for her work sitting on the couple's mantel (Tony has no Emmys, but Alissa let him hold them once). Together, while working our separate day jobs and raising our three young children, we run BLOK from our home in the suburbs just outside of Philadelphia.
            </div>
            {/* <div
              className = {`${window.innerWidth >= 1024 && window.innerWidth <1100 ? 'text-new' : 'text'} Gothic-Regular`}
            >
              We spent a lot of time this past year cooking together at home. We quickly realized there were no fun, contemporary and easy cooking classes. That’s when the idea of BLOK was born! We came up with an attractive custom-designed piece of hardware that creates a virtual experience for at-home chefs where they can learn to prepare delicious and healthy meals in a fun and engaging way. BLOK is so much more than a smart cutting board, it’s a community of foodies all over the world!
            </div> */}
            <div
              className = {`${window.innerWidth >= 1024 && window.innerWidth <1100 ? 'text-last-para-new' : 'text-last-para'} Gothic-Regular`}
              //className="text-last-para Gothic-Regular"
            >
              We spent a lot of time this past year cooking together at home. We quickly realized there were no fun, contemporary and easy cooking classes. That’s when the idea of BLOK was born! We came up with an attractive custom-designed piece of hardware that creates a virtual experience for at-home chefs where they can learn to prepare delicious and healthy meals in a fun and engaging way. BLOK is so much more than a smart cutting board, it’s a community of foodies all over the world!
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ background: "black", padding: "5%", width: "100%" }}>
        <div className="d-flex align-center justify-content">
          <StyledButton style = {{color:'black',borderRadius:50,fontWeight:700}}
          className = {`${window.innerWidth >=320 && window.innerWidth < 475 
            ? 'fs-20'
            : window.innerWidth >=475 && window.innerWidth <=768 ? 'fs-25' : 'fs-36'} bg-white plp-5 prp-5`}
            onClick={(e) => {
              props.history.push("/subscription");
            }}
          >
            SHOP THE BLOK
          </StyledButton>
        </div>
      </Box>
      <Footer />
    </StyleWrapper>
  );
};

export default AboutUs;
