
/* eslint-disable */
export const contactUsEvent = () => {
    fbq('track', 'Contact');
}

export const signupEvent = () => {
    fbq('track', 'CompleteRegistration');
}
export const addToCartEvent = () => {
    fbq('track', 'AddToCart');
}

export const initiateCheckoutEvent = () => {
    fbq('track', 'InitiateCheckout');
}

export const purchaseSuccessEvent = () => {
    fbq('track', 'Purchase');
}

export const loginEvent = () => {
    fbq('track', 'Login');
}

export const pageViewEvent = () => {
    fbq('track', 'PageView');
}

export const deleteAccountEvent = () => {
    fbq('track', 'DeleteAccount');
}

export const forgotPasswordEvent = () => {
    fbq('track', 'ForgotPassword');
}

export const paymentFailedEvent = () => {
    fbq('track', 'PaymentFailed');
}

export const logoutEvent = () => {
    fbq('track', 'Logout');
}