import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, history, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LandingPage from "../views/LandingPage/LandingPage";
import AboutUs from "../views/AboutUs/AboutUs";
import Subscription from "../views/Subscription/Subscription";
import ContactUs from "../views/ContactUs/ContactUs";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy";
import Terms from "../views/Terms/Terms";
import PaymentSuccess from "../views/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "../views/PaymentFailed/PaymentFailed";
import DataDeletionInstructions from "../views/DataDeletionInstructions/DataDeletionInstructions";
import Login from "../views/Login/Login";
import SignUp from "../views/SignUp/SignUp";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import DeleteAccount from "../views/DeleteAccount/DeleteAccount";

const routes = (props) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Switch>
            <Route path = "/login" component={Login}/>
            <Route path = "/sign-up" component={SignUp}/>
            <Route path = "/forgot-password" component={ForgotPassword}/>
            <Route path = "/terms" component = {Terms}/>
            <Route path = "/privacy-policy" component = {PrivacyPolicy}/>
            <Route path="/about-us" component={AboutUs} />
            <Route path="/landing-page" component={LandingPage} />
            <Route path="/subscription" component={Subscription} />
            <Route path = "/contact-us" component = {ContactUs} />
            <Route path = "/payment-success" component={PaymentSuccess}/>
            <Route path = "/payment-failed" component={PaymentFailed}/>
            <Route path = "/data-deletion-instructions" component={DataDeletionInstructions}/>
            <Route path = "/delete-account" component={DeleteAccount}/>

            <Route path="/" render={() => <Redirect to="/landing-page" />} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default routes;
